<template>
  <div class="ma-md-4">
    <StepItem
      v-for="(config, index) in configs"
      :key="index"
      v-bind="config"
      :active="true"
      :isLast="index == configs.length - 1"
    />
  </div>
</template>

<script>
import StepItem from "./stepItem.vue";
export default {
  components: {
    StepItem,
  },
  data: () => ({
    activeIndex: 0,
    configs: [
      { title: "01", content: "簽約前作業", barStyle: {'height': '188px'} },
      { title: "02", content: "啟動", barStyle: {'height': '215px'} },
      { title: "03", content: "驗收", barStyle: {'height': '205px'} },
      { title: "04", content: "結案" },
    ],
  }),
  created() {
    this.$root.$on("infoWheelEvent", this.handleWheelEvent);
  },
  beforeDestroy() {
    this.$root.$off("infoWheelEvent", this.handleWheelEvent);
  },
  methods: {
    handleWheelEvent(deltaY) {
      // wheel向下
      if (deltaY > 0) {
        if (this.activeIndex >= this.configs.length - 1) return;
        this.activeIndex += 1;
        return;
      }

      if (this.activeIndex == 0) return;
      this.activeIndex -= 1;
    },
  },
};
</script>