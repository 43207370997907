<template>
  <div class="d-md-block d-none">
    <div class="progress-intro">
      <span class="progress-number">{{ title }}</span
      ><span class="progress-title">{{ content }}</span>
    </div>
    <div v-if="!isLast" class="progress-bar" :class="barClassName" :style="progressBarStyle">
      <div class="progress-bar-inner"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    active: Boolean,
    isLast: Boolean,
    barStyle: Object,
  },
  computed: {
    barClassName() {
      if(this.active) return 'active'
      return null 
    },
    progressBarStyle() {
      if(!this.barStyle) return null
      return this.barStyle
    },
  }
};
</script>